/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
// import { handleGaCustomEvent } from "../../utils/functions";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./partials/Logo";
import HeaderStrip from "../sections/HeaderStrip";

const menuItems = [
  {
    name: "Features",
    subMenu: {
      // product: {
      //   heading: "Product",
      //   values: [
      //     {
      //       heading: "Datazip",
      //       detail: "Full feature data platform",
      //       color: "#6061F6",
      //       innerLink: "/",
      //       outerLink: null,
      //     },
      //     {
      //       heading: "Ingestion Stack",
      //       detail: "Extract and Load your data",
      //       color: "#099BEC",
      //       innerLink: "/ingestion",
      //       outerLink: null,
      //     },
      //   ],
      // },
      feature: {
        // heading: "Features",
        tabs: [
          {
            heading: "Datazip",
            value: [
              {
                heading: "ELT or Ingestion",
                detail: "Sync data from 150+ data sources",
                color: "#22B07D",
                innerLink: "/onestack-data/ingestion",
                outerLink: null,
              },
              {
                heading: "Storage",
                detail: "Columnar data warehouse",
                color: "#FF8700",
                innerLink: "/onestack-data/storage",
                outerLink: null,
              },
              {
                heading: "Business Intelligence",
                detail: "100+ charts, alerts and reporting",
                color: "#32A7E2",
                innerLink: "/onestack-data/business-intelligence",
                outerLink: null,
              },
              {
                heading: "Embedded Analytics",
                detail: "Easily integrate and embed analytics ",
                color: "#9BCA43",
                innerLink: "/onestack-data/solutions/embedded-analytics",
                outerLink: null,
              },
              {
                heading: "OpenEngine",
                detail: "On-demand virtual data warehouse ",
                color: "#4154fe",
                innerLink: "/onestack-data/openengine",
                outerLink: null,
              },
            ],
          },
          // {
          //   heading: "Ingestion Stack",
          //   value: [
          //     {
          //       heading: "ELT or Ingestion",
          //       detail: "Sync data from 150+ data sources",
          //       color: "#FF8700",
          //       innerLink: "/connectors",
          //       outerLink: null,
          //     },
          //   ],
          // },
        ],
        values: [
          {
            heading: "Embedded Analytics",
            detail: "Easily integrate and embed analytics ",
            color: "#9BCA43",
            innerLink: "/onestack-data/solutions/embedded-analytics",
            outerLink: null,
          },
        ],
      },
    },
  },
  // {
  //   name: "Solutions",
  //   subMenu: {
  //     product: {
  //       heading: null,
  //       values: [
  //         {
  //           heading: "Product",
  //           color: "#32A7E2",
  //           innerLink: "/solutions/product",
  //           outerLink: null,
  //         },
  //         {
  //           heading: "Marketing",
  //           color: "#B548C6",
  //           outerLink: null,
  //           innerLink: "/solutions/marketing",
  //         },
  //         {
  //           heading: "Revenue Operations",
  //           color: "#FF8700",
  //           outerLink: null,
  //           innerLink: "/solutions/revenue-operations",
  //         },
  //         {
  //           heading: "Data Analyst",
  //           color: "#22B07D",
  //           outerLink: null,
  //           innerLink: "/solutions/data-analyst",
  //         },
  //         {
  //           heading: "Operations",
  //           color: "#E26E6E",
  //           outerLink: null,
  //           innerLink: "/solutions/operations",
  //         },
  //         {
  //           heading: "Finance",
  //           color: "#6061F6",
  //           outerLink: null,
  //           innerLink: "/solutions/finance",
  //         },
  //       ],
  //     },
  //   },
  // },
  {
    name: "Connectors",
    subMenu: null,
    outerLink: null,
    innerLink: "/onestack-data/connectors",
  },
  {
    name: "Pricing",
    subMenu: null,
    outerLink: null,
    innerLink: "/onestack-data/pricing",
  },
  // {
  //   name: "Resources",
  //   subMenu: {
  //     product: {
  //       heading: null,
  //       values: [
  //         {
  //           heading: "Case Studies",
  //           color: "#32A7E2",
  //           innerLink: "/case-studies",
  //           outerLink: null,
  //         },
  //         {
  //           heading: "Documentation",
  //           color: "#B548C6",
  //           innerLink: null,
  //           outerLink: "https://docs.datazip.io/s/home",
  //         },
  //         {
  //           heading: "Community",
  //           color: "#22B07D",
  //           innerLink: null,
  //           outerLink: "https://datatribe.datazip.io/",
  //         },
  //       ],
  //     },
  //   },
  // },
  {
    name: "Blog",
    subMenu: null,
    outerLink: "/blog",
    innerLink: null,
  },
  {
    name: "Case Studies",
    subMenu: null,
    outerLink: null,
    innerLink: "/onestack-data/case-studies",
  },
  {
    name: "Docs",
    subMenu: null,
    outerLink: "https://docs.datazip.io/s/home",
    innerLink: null,
  },
  // {
  //   name: "About Us",
  //   subMenu: null,
  //   outerLink: null,
  //   innerLink: "/onestack-data/about-us",
  // },
];

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

// Common function to switch between different button sets
const HeaderButtons = (isOpenEnginePage, isHomePage, history) => (
  <div className="header-btns">
    <button
      className={classNames(
        "button__main",
        "button__login",
        !isHomePage && "button__login-secondary"
      )}
      onClick={() => history.push("/onestack-data/login")}
    >
      {isOpenEnginePage ? "Sign In" : "Login"}
    </button>
    {!isOpenEnginePage && (
      <button
        className={classNames("button__main", "button__signup")}
        onClick={() => history.push("/onestack-data/sign-up")}
      >
        Start for Free
      </button>
    )}
  </div>
);

const GetProductLink = (item, closeMenu) => {
  if (item?.subMenu) {
    return <Link to={""}>{item?.name}</Link>;
  }
  if (item?.outerLink) {
    return (
      <a href={item?.outerLink} target="_blank" rel="noopener noreferrer">
        {item?.name}
      </a>
    );
  }
  if (item?.innerLink) {
    return (
      <Link to={item?.innerLink} onClick={closeMenu}>
        {item?.name}
      </Link>
    );
  }
};

const GetSubItem = (value) => {
  return (
    <div className="item">
      <div
        className="box color-a"
        style={{ background: `${value.color}` }}
      ></div>
      <div className="product-details">
        <span className={`${value.detail ? "name" : "name-1"}`}>
          {value.heading}
        </span>
        {value?.detail && <span className="detail">{value?.detail}</span>}
      </div>
    </div>
  );
};

const FeatureBox = ({ item }) => {
  // const [selected, setSelected] = useState(0);
  return (
    <div className="product-box feature-box">
      {/* <div className="heading" style={{ color: " #FFBB24" }}>
        {item?.feature?.heading}
      </div> */}
      {/* <div className="product-tabs">
        {item?.feature.tabs.map((value, index) => (
          <div
            className={`product-tab ${index === selected ? "selected" : ""}`}
            key={index}
            onClick={() => setSelected(index)}
          >
            {value.heading}
          </div>
        ))}
      </div> */}
      {item?.feature?.tabs[0]?.value?.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item?.outerLink ? (
              <a
                href={item?.outerLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {GetSubItem(item)}
              </a>
            ) : (
              <Link to={item?.innerLink}>{GetSubItem(item)}</Link>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const RenderSubMenu = (item) => {
  return (
    <div className="dropdown-wrapper">
      <div className="header-dropdown">
        {item.product && (
          <div className="product-box">
            {item.product?.heading && <div className="heading">Product</div>}
            {item.product?.values?.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  {value?.outerLink ? (
                    <a
                      href={value?.outerLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {GetSubItem(value)}
                    </a>
                  ) : (
                    <Link to={value?.innerLink}>{GetSubItem(value)}</Link>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        )}

        {item?.feature && <FeatureBox item={item} />}
      </div>
    </div>
  );
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);
  const history = useHistory();
  const { pathname } = useLocation();
  const isHomePage =
    pathname === "/onestack-data" || pathname === "/onestack-data/";
  const isOpenEnginePage = pathname.includes("/openengine");

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    document.body.classList.add("no-scroll");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    document.body.classList.remove("no-scroll");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };
  const classes = classNames(
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  return (
    <header {...props} className={`${classes} absolute z-index`}>
      {!isOpenEnginePage ? (
        <HeaderStrip
          message="We are launching something new."
          linkText="Check us out"
          linkUrl="/onestack-data/openengine"
        />
      ) : (
        <HeaderStrip
          message="Check us out on "
          linkText="Product Hunt"
          linkUrl="https://www.producthunt.com/products/openengine-by-datazip"
        />
      )}
      <div className="container header-container">
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
          style={{ flexWrap: "wrap" }}
        >
          <Logo header />

          {!hideNav && (
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames("header-nav", isActive && "is-active")}
              >
                <div className="header-nav-inner" style={{ flexWrap: "wrap" }}>
                  <ul
                    className={classNames(
                      "list-reset text-xs",
                      navPosition && `header-nav-${navPosition}`,
                      isHomePage && "home-header"
                    )}
                  >
                    {menuItems.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={
                            isHomePage
                              ? "nav-link-primary"
                              : "nav-link-secondary"
                          }
                        >
                          {GetProductLink(item, closeMenu)}
                          {item?.subMenu && (
                            <svg
                              className="invert-chevron"
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.1663 6L8.49967 10.6667L3.83301 6"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                          {item?.subMenu && RenderSubMenu(item.subMenu)}
                        </li>
                      );
                    })}

                    {/* <li>
                      <a
                        href="https://datatribe.datazip.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          handleGaCustomEvent(
                            "external clicks",
                            "community click"
                          );
                        }}
                      >
                        Community
                      </a>
                    </li> */}
                    {/* <li>
                      <a onClick={(e) => e.preventDefault()}>
                        <button
                          className={classNames(
                            "button__main",
                            "button__signup",
                            !isHomePage && "button__signup-secondary"
                          )}
                          onClick={() => history.push("/onestack-data/sign-up")}
                        >
                          Sign Up
                        </button>
                      </a>
                    </li> */}
                  </ul>
                  {HeaderButtons(isOpenEnginePage, isHomePage, history)}
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
