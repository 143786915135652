/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
// import { handleGaCustomEvent } from "../../utils/functions";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./partials/Logo";

const menuItems = [
  {
    name: "Product",
    subMenu: {
      items: [
        {
          name: "Olake",
          logo: require("../../assets/images/olake-nav-inactive.svg"),
          activeLogo: require("../../assets/images/olake-nav-active.svg"),
          path: "/olake",
        },
        {
          name: "Flatbread",
          logo: require("../../assets/images/flatbread-nav-inactive.svg"),
          activeLogo: require("../../assets/images/flatbread-nav-active.svg"),
          path: "/flatbread",
        },
        {
          name: "OneStack Data",
          logo: require("../../assets/images/one-stack-nav-inactive.svg"),
          activeLogo: require("../../assets/images/one-stack-nav-active.svg"),
          path: "/onestack-data",
        },
      ],
    },
  },
  {
    name: "Docs",
    subMenu: null,
    link: (isOlakepage = false) => {
      if (isOlakepage) {
        return {
          type: "outer",
          url: "https://datazip.io/olake/docs",
        };
      }
      return {
        type: "outer",
        url: "https://docs.datazip.io/s/home",
      };
    },
  },
  {
    name: "Blog",
    subMenu: null,
    link: (isOlakepage = false) => {
      return {
        type: "outer",
        url: "/blog",
      };
    },
  },
];

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

// Common function to switch between different button sets
const HeaderButtons = (isOlakePage, isFlatBreadPage) => (
  <div className="header-btns">
    {/* <div className="olake-header"> */}
    {isOlakePage && (
      <>
        <a
          href="https://join.slack.com/t/getolake/shared_invite/zt-2utw44do6-g4XuKKeqBghBMy2~LcJ4ag"
          target="_blank"
          rel="noopener noreferrer"
          className="slack-button"
        >
          <img
            src={require("../../assets/images/slack-default.svg")}
            alt="slack"
            className="slack-button-default"
          />
        </a>
        <a
          href="https://github.com/datazip-inc/olake"
          target="_blank"
          rel="noopener noreferrer"
          className="github-button"
        >
          <img
            src={require("../../assets/images/github-default.svg")}
            alt="github"
            className="github-button-default"
          />
        </a>

        <a href="/olake?utm_source=navbar_waitlist_btn&utm_medium=olake_site_navbar&utm_campaign=olake&utm_content=olake_cta#olake-form-product">
          <button className="olake-joinlist-button">Join Waitlist</button>
        </a>
      </>
    )}

    {isFlatBreadPage && (
      <a href="/flatbread#flatbread-form-product">
        <button className="olake-joinlist-button">Join Waitlist</button>
      </a>
    )}

    {/* </div> */}
  </div>
);

const GetProductLink = (item, closeMenu, isOlakePage) => {
  if (item?.subMenu) {
    return <Link to={""}>{item?.name}</Link>;
  }
  const link = item.link(isOlakePage);
  if (link.type === "outer") {
    return (
      <a href={link?.url} target="_blank" rel="noopener noreferrer">
        {item?.name}
      </a>
    );
  } else {
    return (
      <Link to={link?.url} onClick={closeMenu}>
        {item?.name}
      </Link>
    );
  }
};

const RenderSubMenu = (item) => {
  const history = useHistory();
  const onItemClick = (value) => {
    history.push(value);
  };
  return (
    <div className="dropdown-wrapper">
      <div className="header-dropdown">
        {item.items.map((nav) => {
          return (
            <div
              className="header-dropdown-item"
              onClick={() => onItemClick(nav.path)}
            >
              <img src={nav.logo} alt="nav-inactive" className="inactive-img" />
              <img
                src={nav.activeLogo}
                alt="nav-active"
                className="active-img"
              />
              {nav.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const NewHeader = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);
  const { pathname } = useLocation();
  const isOlakePage = pathname === "/olake";
  const isFlatBreadPage = pathname === "/flatbread";

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    document.body.classList.add("no-scroll");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    document.body.classList.remove("no-scroll");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };
  const classes = classNames(
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  return (
    <header {...props} className={`${classes} absolute z-index`}>
      <div className="container header-container">
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
          style={{ flexWrap: "wrap" }}
        >
          <Logo header />

          {!hideNav && (
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames("header-nav", isActive && "is-active")}
              >
                <div className="header-nav-inner" style={{ flexWrap: "wrap" }}>
                  <ul
                    className={classNames(
                      "list-reset text-xs",
                      navPosition && `header-nav-${navPosition}`
                      // isHomePage && "home-header"
                    )}
                  >
                    {menuItems.map((item, index) => {
                      return (
                        <li key={index} className={"nav-link-secondary"}>
                          {GetProductLink(item, closeMenu, isOlakePage)}
                          {item?.subMenu && (
                            <svg
                              className="invert-chevron"
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.1663 6L8.49967 10.6667L3.83301 6"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                          {item?.subMenu && RenderSubMenu(item.subMenu)}
                        </li>
                      );
                    })}
                  </ul>
                  {HeaderButtons(isOlakePage, isFlatBreadPage)}
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

NewHeader.propTypes = propTypes;
NewHeader.defaultProps = defaultProps;

export default NewHeader;
